import React, { useContext } from 'react';
import Logo from "../../../assets/Heading/Big_Logo.svg";
import Logo_Dark from "../../../assets/Heading/Big_Logo_Dark.svg";
import Title from "../../../assets/Heading/Main_title2.png";
import Title_Dark from "../../../assets/Heading/Main_title_Dark.svg";
import MyTimer from '../../../Components/Timer';
import Hour from "../../../assets/Heading/Hourglass.svg";
import Hour_Dark from "../../../assets/Heading/Hourglass_Dark.svg";
import { ThemeContext } from '../../../Contexts/Theme';
import {
    HeadingContainer,
    LeftContainer,
    RightContainer,
    KarsoghImage,
    Images,
    SignUpButton,
    TimerContainer,
    Timer,
    KarsoghTitle,
    Arrow,
    SignUpText,
    HourGlass
} from "./HeadingStyle.js";

const Heading = (props) => {
    const time = new Date();
    const start_time = new Date("2023-8-16");
    console.log(Math.abs(start_time - time));
    time.setSeconds(Math.abs(start_time - time) * 0.001);

    const [DarkTheme] = useContext(ThemeContext);

    return (
        <HeadingContainer id="Home">
            <LeftContainer>
                <Images>
                    <SignUpButton>
                        <SignUpText>
                            مرحله حضوری
                        </SignUpText>
                        <Arrow />
                    </SignUpButton>

                    <TimerContainer>
                        {/* <Timer isDark={DarkTheme} >
                            <HourGlass src={(DarkTheme) ? Hour_Dark : Hour} />
                            <MyTimer expiryTimestamp={time} label={''} />
                        </Timer> */}
                        <KarsoghTitle src={(DarkTheme) ? Title_Dark : Title} />
                    </TimerContainer>
                </Images>
            </LeftContainer>
        
            <RightContainer>
                <KarsoghImage src={(DarkTheme) ? Logo_Dark : Logo} />
            </RightContainer>
        </HeadingContainer>
    );
}
 
export default Heading;
