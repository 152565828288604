export const schoolsByCity = {
  تبریز: [
    "آیت الله شهید مدنی",
    "فرزانگان ۱",
    "فرزانگان ۳",
    "علامه جعفری ناحیه ۲",
    "فرزانگان ناحیه ۴",
    "استعدادهای درخشان علامه طباطبایی",
    "فرزانگان ناحیه ۵ تبریز",
  ],
  بروجن: ["مدرسه فرزانگان دوره اول بروجن", "شهید بهشتی"],
  زاهدان: [
    "فرزانگان ۲ دوره اول",
    "فرزانگان یک ناحیه دو زاهدان",
    "تیزهوشان شهید بهشتی ۱",
  ],
  اصفهان: [
    "شهید اژه‌ای ۱",
    "شهید اژه ای ۲",
    "فرزانگان امین ۱",
    "فرزانگان امین ۲",
    "علمازاده",
    "امام باقر (ع)",
    "همت نو",
  ],
  کلاله: ["فرزانگان"],
  مشهد: ["شهید هاشمی نژاد ۱", "فرزانگان ۲", "فرزانگان ۱", "شهید هاشمی نژاد ۲"],
  پردیس: ["پسرانه شهید بهشتی", "استعداد های درخشان شهید بهشتی رودهن"],
  اراک: ["پسرانه علامه حلی", "مدرسه فرزانگان", "علامه حلی ۱"],
  کرمانشاه: ["فرزانگان", "فرزانگان ۱", "شهید بهشتی"],
  ایلام: ["فرزانگان", "پسرانه سمپاد شهید بهشتی", "فرزانگان ایلام"],
  یزد: [
    "استعداد های درخشان شهید صدوقی دوره اول",
    "شهید رمضانخوانی",
    "دبیرستان دوره اول فرزانگان ناحیه ۲ یزد",
  ],
  دزفول: ["شیخ مرتضی انصاری"],
  کاشان: ["شهید ترکیان فر", "دبیرستان سینائیان کاشان"],
  "تربت حیدریه": ["شهید بهشتی", "فرزانگان"],
  همدان: ["دخترانه فرزانگان ۱", "فرزانگان ۲", "علامه حلی ۱"],
  کرج: [
    "دبیرستان استعداد های درخشان شهید باهنر ۳",
    "استعداد های درخشان شهید باهنر ۴",
    "فرزانگان ۴ کرج",
    "شهید باهنر ۱",
    "فرزانگان ۳ کرج",
    "فرزانگان ۱",
  ],
  اردبیل: ["فرزانگان ۱ دوره اول", "فرزانگان ۲ دوره اول"],
  تهران: [
    "دبيرستان دوره اول فرزانگان ۱",
    "علامه حلی ۱",
    "فرزانگان ۲",
    "دخترانه فرازنگان ۸",
    "فرزانگان ۵",
    "فرزانگان ۶",
    "حلی ۵",
  ],
  سمنان: ["شهید بهشتی", "فرزانگان"],
  "اسلام آبادغرب": ["پسرانه شهید بهشتی", "تیزهوشان فرزانگان"],
  "خرم آباد": ["دخترانه فرزانگان"],
  بهشهر: ["فرزانگان"],
  سنندج: [
    "دخترانه دبیرستان فرزانگان ۱ دوره اول",
    "تیزهوشان شهید بهشتی",
    "فرزانگان ناحیه ۱ سنندج",
  ],
  شهرکرد: ["شهید بهشتی", "استعداد های درخشان شهید بهشتی شهرکرد"],
  شیراز: ["فرزانگان ۱"],
  مریوان: ["شهید بهشتی تیزهوشان مریوان"],
  ورامین: ["شهید ستاری"],
  ساوجبلاغ: ["فرزانگان ۵", "فرزانگان ۵ البرز", "فرزانگان ۵ ساوجبلاغ"],
  گنبدکاووس: ["فرزانگان"],
  اسفراین: ["فرزانگان"],
  رشت: ["فرزانگان رشت"],
  مرند: ["علامه جعفری مرند", "فرزانگان"],
  "مشکین شهر": ["فرزانگان", "شهید بهشتی ۱", "دخترانه فرزانگان یاس"],
  قوچان: ["فرزانگان"],
  کرمان: ["فرزانگان"],
  فارسان: ["شهید بهشتی"],
  میانه: ["علامه امینی", "دخترانه فرزانگان"],
  شیروان: ["مدرسه شهید بهشتی ۱", "فرزانگان ۱", "فرزانگان"],
  گچساران: [
    "استعداد های درخشان شهید بهشتی",
    "استعدادهای درخشان فرزانگان گچساران دوره اول",
  ],
  گناباد: ["فرزانگان دوره اول گناباد"],
  فردیس: ["دخترانه فرزانگان ۲", "شهید باهنر ۳"],
  شهریار: ["استعداد های درخشان دارالفنون", "دخترانه فرزانگان شهریار"],
  "پارس آباد": ["فرزانگان۲"],
  لارستان: ["فرزانگان"],
  زنجان: ["دبیرستان شهید بهشتی منطقه ۲ زنجان", "شهید بهشتی ۱"],
  بناب: ["فرزانگان بناب"],
  بندرلنگه: ["دبیرستان استعدادهای درخشان ثامن الحجج"],
  جلفا: ["علامه جعفری مرند"],
  طبس: ["دخترانه فرزانگان شهدای سایپا"],
  میاندوآب: ["تیزهوشان شهید بهشتی"],
  عجب_شیر: ["علامه حلی"],
  ارومیه: ["شهید بهشتی سمپاد"],
  "خمینی شهر": ["فرزانگان خمینی شهر", "شهید اژه ای"],
  بویراحمد: ["شهید بهشتی یاسوج"],
  بروجرد: ["شهید بهشتی"],
  لاهیجان: ["فرزانگان امام رضا", "استعدادهای درخشان شهید بهشتی لاهیجان"],
  بابل: ["فرزانگان"],
  جیرفت: ["فرزانگان"],
  مراغه: [
    "استعداد های درخشان فرزانگان مراغه",
    "مرکز استعداد های درخشان شهید بهشتی تیزهوشان مراغه",
  ],
  زابل: ["تیزهوشان", "علامه حلی زابل نادر نخعی مقدم"],
  بیرجند: ["پسرانه شهید بهشتی تیزهوشان"],
  ملایر: ["فرزانگان دوره اول"],
  اردکان: ["شهید یحی ذوالفقاری"],
  سراب: ["فرزانگان سراب", "دبیرستان استعدادهای درخشان ابن سینا سراب"],
  خدابنده: ["فرزانگان"],
  "نجف آباد": [
    "فرزانگان امین دوره اول نجف آباد(دکتر فاتح)",
    "فرزانگان امین ۱ نجف اباد",
    "فرزانگان امین نجف اباد",
  ],
  نوشهر: ["استعداد های درخشان"],
  لنگرود: ["فرزانگان لنگرود", "شهید بهشتی"],
  اهر: ["فرزانگان", "فرزانگان اهر"],
  مسجدسلیمان: ["استعداد های درخشان فرزانگان"],
  بندرعباس: ["فرزانگان ۲"],
  رودسر: ["فرزانگان لنکرود"],
  "آران وبیدگل": ["شهید اژه ای"],
  لنجان: ["فرزانگان امین نجف آباد"],
  بجنورد: ["فرزانگان"],
  اسلامشهر: ["تیزهوشان شهید رجایی"],
  نظرآباد: ["فرزانگان ۵"],
  الیگودرز: ["پروین اعتصامی", "فرزانگان"],
  ساوه: ["فرزانگان"],
  دماوند: [
    "پسرانه دبیرستان استعداد های درخشان شهید بهشتی رودهن",
    "دبیرستان استعدادهای درخشان شهید بهشتی",
  ],
  آذرشهر: ["قدسیه"],
  سقز: ["فرزانگان سقز"],
  قزوین: ["فرزانگان شهیدان شالباف"],
  چالوس: ["فرزانگان"],
  میبد: ["شهید یحی ذوالقاری"],
  رباطکریم: ["ایران"],
  دورود: ["َشهید بهشتی تیزهوشان"],
  گرگان: ["فرزانگان"],
  پارسیان: ["استعداد های درخشان ثامن الحجج"],
  نیشابور: ["فرزانگان نیشابور"],
  اهواز: ["دبیرستان شهید بهشتی ۲ اهواز"],
};
