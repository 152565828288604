import React, { useCallback, useContext, useEffect, useReducer, useRef, useState } from 'react';
import ReactPaginate from 'react-paginate';
import Arrow_Icon from "../../../assets/Team/Arrow_Down.svg";
import RightArrow from "../../../assets/Team/right-arrow.svg";
import user from "../../../assets/Team/User.svg";
import { ThemeContext } from '../../../Contexts/Theme';
import {
    TeamContainer,
    Header,
    More,
    Pages,
    Filters,
    Container,
    Description,
    Title,
    Part,
    Image,
    Pic,
    Text,
    Name,
    Objective,
    MoreButton,
    Arrow
} from './TeamStyle.js';

const Team = (props) => {
    const [Team, setTeam] = useState([]);
    const [DarkTheme] = useContext(ThemeContext);
    const [HasteTeam, setHasteTeam] = useState({});
    const [showMore, setShowMore] = useState(false);
    const [currentPage, setCurrentPage] = useState(0);
    const pageData = useRef([]);
    const [pageCount, setPageCount] = useState(1);
    const MemberPerPage = (window.innerWidth > 940) ? 8 : ((window.innerWidth > 700) ? 9 : 8);

    // pagination:
    const handlePageClick = ({selected: selectedPage}) => {
        setCurrentPage(selectedPage);
    }

    let offset = currentPage * MemberPerPage;

    // fetch data from back:
    const fetchData = useCallback(async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/u/roles/`);
            if(!response.ok) {
                throw new Error('Something went wrong!');
            }

            const data = await response.json();
            // sort with their profile pic if they have become higher
            for (let i = 0; i < data.length; i++) {
                data[i]['items'].sort((a, b) => {
                    if(a['image'] && !b['image'])
                        return -1;
                    else if(!a['image'] && b['image'])
                        return 1;
                    else
                        return 0;
                });
            }
            setTeam(data);
            for(let i = 0; i < data.length; i++) {
                if(data[i]['title'] === 'هسته مرکزی'){
                    setHasteTeam(data[i]);
                    break;
                }
            }
        }
        catch(error) {
            console.log(error);
        }
    }, [setHasteTeam]);

    useEffect(() => {
        const temp = async () => {
            await fetchData();
        }

        temp();
    }, [fetchData]);

    const handleShowMore = () => {
        setShowMore(!showMore);

        let temp = [];
        for(let i = Team.length - 1; i >= 0; i--) {
            temp = temp.concat(Team[i]['items']);
        }
        console.log(temp);
        for (let i = 0; i < temp.length; i++) {
            temp.sort((a, b) => {
                if(a['image'] && !b['image'])
                    return -1;
                else if(!a['image'] && b['image'])
                    return 1;
                else
                    return 0;
            });
        }

        pageData.current = temp;
        setPageCount(Math.ceil(pageData.current.length / MemberPerPage));
    }

    // pagination filters:

    // force component to re-render after data updates:
    const [, forceUpdate] = useReducer(x => x + 1, 0);

    function handleClick() {
        forceUpdate();
    }

    const handleFilters = (event) => {
        const list = document.getElementById('filters').getElementsByTagName('div');
        const element = document.getElementById(event.currentTarget.id);
        
        for (let i = 0; i < list.length; i++) {
            if (list[i].id === element.id){
                if (element.className.indexOf("fill") > -1)
                    element.classList.remove("fill");
                
                else
                    element.classList.add("fill");
            }

            else
                list[i].classList.remove("fill");
        }

        let anythingFilled = false;

        for (let i = 0; i < list.length; i++) {
            if(list[i].className.indexOf('fill') > -1) {
                
                // updating data:
                const data = [...Team[i]['items']];
                pageData.current = data;
                setPageCount(Math.ceil(pageData.current.length / MemberPerPage));
                setCurrentPage(0);
                anythingFilled = true;
                break;
            }
        }

        if(!anythingFilled) {
            let temp = [];
            for(let i = 0; i < Team.length; i++) {
                temp = temp.concat(Team[i]['items']);
            }
            pageData.current = temp;
            setPageCount(Math.ceil(pageData.current.length / MemberPerPage));
            setCurrentPage(0);
        }

        handleClick();
    }


    return (
        <TeamContainer id="Team" >
          <Header>
              <Title>
                  کادر برگزاری
              </Title>
              <More show={showMore}>
                <Pages isDark={DarkTheme}>
                    {
                        (showMore) &&
                        <ReactPaginate
                            previousLabel={<img src={RightArrow} alt="<-" />}
                            nextLabel={<img src={RightArrow} alt="->" />}
                            pageCount={pageCount}
                            onPageChange={handlePageClick}
                            containerClassName={'pagination'}
                            previousLinkClassName={"previous"}
                            nextLinkClassName={"next"}
                            disabledClassName={'disabled'}
                            activeClassName={'active'}
                            forcePage = {currentPage}
                        />
                    }
                </Pages>
                <Filters id='filters'>
                {
                    (Team.length !== 0) && (showMore) &&
                    Team.map((element) => {
                        return(
                            <Container key={element['id']} id={ 'filter ' + element['id']} className='' onClick={handleFilters} isDark={DarkTheme} >
                                {element['title']}
                            </Container>
                        );
                    })
                }
                </Filters>
              </More>
          </Header>
          <Description>
                {
                    (Object.keys(HasteTeam).length !== 0) && (!showMore) &&
                    HasteTeam['items'].slice(0, (window.innerWidth > 910? 8 : ((window.innerWidth > 633) ? 9 : 8))).map((element) => {
                        return(
                            <Part key={element['id']} >
                                <Image>
                                    <Pic src={element['image']} alt={'Image'} />
                                </Image>
                                <Text>
                                    <Name>
                                        {element['fullName']}
                                    </Name>
                                    <Objective isDark={DarkTheme} >
                                        {HasteTeam['title'] + "، " + (element["specialTask"] ? element["specialTask"]: '')}
                                    </Objective>
                                </Text>
                            </Part>
                        );
                    })
                }
                {
                    (pageData.current.length !== 0) && (showMore) &&
                    pageData.current.slice(offset, offset + MemberPerPage).map((element) => {
                        return(
                            <Part key={element['id']} >
                                <Image>
                                    <Pic src={(element['image']) ? element['image'] : user} alt={"Image"} />
                                </Image>
                                <Text>
                                    <Name>
                                        {element['fullName']}
                                    </Name>
                                    <Objective isDark={DarkTheme} >
                                        {(Team[0]['items'].find((user) => {return user['fullName'] === element['fullName'] && user['specialTask'] === element['specialTask']})) ? Team[0]['title'] :
                                         (Team[1]['items'].find((user) => {return user['fullName'] === element['fullName'] && user['specialTask'] === element['specialTask']})) ? Team[1]['title'] :
                                         (Team[2]['items'].find((user) => {return user['fullName'] === element['fullName'] && user['specialTask'] === element['specialTask']})) ? Team[2]['title'] : Team[3]['title']
                                        }
                                        {
                                            (element["specialTask"] ? "، " + element["specialTask"]: '')
                                        }
                                    </Objective>
                                </Text>
                            </Part>
                        );
                    })
                }
          </Description>
          <MoreButton show={!showMore} >
            <Arrow src={Arrow_Icon} onClick={handleShowMore} />
          </MoreButton>
      </TeamContainer>
    );
}
 
export default Team;
