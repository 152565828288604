import styled from 'styled-components'
import { Link as LinkR } from 'react-router-dom'
import { Link as LinkS } from 'react-scroll'
import { FaTimes } from 'react-icons/fa'
import EstedadFont from "../../../../assets/NavBar/Estedad-Regular.woff2"
import Logo from "../../../../assets/NavBar/Small_Logo.svg"
import Logo_Dark from "../../../../assets/NavBar/Small_Logo_Dark.svg";

export const SidebarContainer = styled.aside`
    padding-top: 2vh;
    align-items: center;
    background: ${props => (props.isDark) ? "#424242": "#f3f3f3"};
    display: grid;
    height: 100%;
    left:0;
    opacity: ${({ isOpen }) => (isOpen ? '100%' : '0')};
    position:fixed;
    top:${({ isOpen }) => (isOpen ? '0' : '-100%')};
    transition:0.3s ease-in-out;
    width: 100%;
    z-index: 999;

    @font-face {
        font-display: swap;
        font-family: 'Estedad';
        font-style: normal;
        font-weight: normal;
        src: url(${EstedadFont}) format('woff');
    }
    font-family: "Estedad";
    font-size:1.2rem;
    font-weight: bold;


    //can not select link
    -webkit-user-select: none;  /* Chrome all / Safari all */
    -moz-user-select: none;     /* Firefox all */
    -ms-user-select: none;      /* IE 10+ */
    user-select: none;          /* Likely future */
`

export const CloseIcon = styled(FaTimes)`
    color: ${props => (props.isDark) ? "#ff3d3d" : "#d20000"};
`

export const Icon = styled.div`
    background:transparent;
    cursor:pointer;
    font-size:2rem;
    outline:none;
    position:absolute;
    right:1.5rem;
    top:1.2rem;
‍‍‍`

export const SidebarWrapper = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    color:#fff;
`

export const SidebarMenu = styled.ul`
    display: flex;
    flex-direction: column;
    margin-bottom:0;
    text-align:center;
    justify-content: center;
    padding-left:0;

    @media screen and (min-height:730px){
        grid-template-rows:repeat(4,10vh);
    }
`

export const SidebarLink = styled(LinkS)`
    align-items:center;
    color: ${props => (props.isDark) ? "#ccc": "#515151"};
    cursor:pointer;
    display:flex;
    font-size: 1.5rem;
    justify-content:center;
    list-style:none;
    text-decoration:none;
    text-decoration:none;
    transition:0.2s ease-in-out;

    &:hover{
        color: ${props => (props.isDark) ? "#fff": "#000"};
        transition:0.2s ease-in-out;
    }

    @media screen and (max-width: 470px){
        font-size: 1.3rem;
    }
`

export const SideBtnWrap = styled.div`
    display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
    justify-content:center;
    width: 100%;
`

export const SidebarRoute = styled(LinkR)`
    background-color: ${props => props.bg};
    border: 2px solid ${props => props.bg};
    border-radius: 50px;
    color: #FFFFFF;
    cursor:pointer;
    
    margin:7px 0;
    outline:none;
    padding: 10px 50px;
    text-decoration: none;
    transition:all 0.2s ease-in-out;
    white-space:nowrap;

    animation: vibrate 2.5s infinite;
    animation-timing-function: linear;

    @keyframes vibrate {

      5%,11%,17% {
        transform: rotate(-2deg);
      }

      8%,14%,20% {
        transform: rotate(2deg);
      }
      21% {
        transform: rotate(0deg);
      }
    }

`

export const KarsoghLogo = styled.div`
    display: none;

    @media screen and (max-width: 830px) {
        background-image: ${props => (props.isDark) ? `url(${Logo_Dark})` : `url(${Logo})`};
        background-size: cover;
        background-repeat: no-repeat;
        display: block;
        position: fixed;
        max-width: 50px;
        max-height: 50px;
        width: 48px;
        height: 48px;
        top: 1rem;
        left: 1rem;
        z-index: 15;
    }

    &:hover{
        cursor: pointer;
        transition: all 0.2s ease-in-out;
    }
`
