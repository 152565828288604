import styled, { keyframes } from "styled-components";
import EstedadFont from "../../../assets/NavBar/Estedad-Regular.woff2";
import EstedadFontBold from "../../../assets/About/Estedad-Bold.woff2";

export const GalleryContainer = styled.div`
  @font-face {
    font-display: swap;
    font-family: "Estedad";
    font-style: normal;
    font-weight: normal;
    src: url(${EstedadFont}) format("woff");
  }
  font-family: "Estedad";
  padding: 2rem 10rem;
  @media screen and (max-width: 1000px) {
    padding: 2rem 5rem;
  }
  @media screen and (max-width: 600px) {
    padding: 2rem 2rem;
  }
`;

const shimmer = keyframes`
to {
  background-position-x: 0%;
}
`;
export const SkeltonLoading = styled.div`
height: 270px;
border-radius:20px;
background: linear-gradient(-45deg, #eee 40%, #fafafa 50%, #eee 60%);
background-size: 300%;
background-position-x: 100%
animation: ${shimmer} 1s infinite linear;
`;

export const Header = styled.div`
  color: #85bc22;
  font-weight: bold;
  @font-face {
    font-display: swap;
    font-family: "EstedadBold";
    font-style: normal;
    font-weight: bold;
    src: url(${EstedadFontBold}) format("woff");
  }
  font-family: "EstedadBold";
  font-size: 2.5rem;
`;

export const ItemContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  @media screen and (max-width: 1000px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media screen and (max-width: 700px) {
    grid-template-columns: repeat(1, 1fr);
  }
  margin-top: 2rem;
`;

export const Item = styled.div`
  .resume-flip {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    min-height: 270px;
    position: relative;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -webkit-perspective: 1200px;
    perspective: 1200px;
    width: 100%;
    height: 100%;
  }
  .resume-flip .resume-list {
    position: relative;
    transition-property: box-shadow, -webkit-transform;
    -webkit-transition-property: -webkit-box-shadow, -webkit-transform;
    transition-property: -webkit-box-shadow, -webkit-transform;
    transition-property: box-shadow, transform;
    transition-property: box-shadow, transform, -webkit-box-shadow,
      -webkit-transform;
    transition-property: box-shadow, transform, -webkit-transform;
    -webkit-transition-duration: 0.6s;
    transition-duration: 0.6s;
    -webkit-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    -webkit-transform-style: inherit;
    transform-style: inherit;
    -webkit-perspective: inherit;
    perspective: inherit;
    height: 100%;
    width: 100%;
    margin-bottom: 30px;
  }
  .resume-flip .resume-list .resume-list-front {
    background-color: #fff;
    z-index: 2;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 20px !important;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-transform-style: inherit;
    transform-style: inherit;
    -webkit-perspective: inherit;
    perspective: inherit;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    height: 100%;
    background-size: cover;
    background-position: center;
  }
  .resume-flip .resume-list .resume-list-front:before {
    content: "";
    position: absolute;
    background-color: #000;
    border-radius: 20px;
    right: 0;
    left: 0;
    height: 100%;
    opacity: 0.3;
  }
  .resume-flip .resume-list .resume-list-front .flip-content {
    -webkit-transform: translate3d(0, 0, 60px) scale(0.85);
    transform: translate3d(0, 0, 60px) scale(0.85);
  }
  .resume-flip .resume-list .resume-list-front .flip-content h5 {
    padding: 11px 20px;
    background: #fff;
    border-radius: 10px !important;
    font-size: 14px;
    border-radius: 2px;
    font-weight: 900;
  }
  .resume-flip .resume-list .resume-list-back {
    -webkit-transform: rotateY(-180deg) rotateZ(-180deg);
    transform: rotateY(-180deg) rotateZ(-180deg);
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    -webkit-transform: rotate3d(0, 1, 0, 180deg);
    transform: rotate3d(0, 1, 0, 180deg);
    z-index: 1;
    background: #85bc22;
    -webkit-transform-style: inherit;
    transform-style: inherit;
    -webkit-perspective: inherit;
    perspective: inherit;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    border-radius: 20px !important;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
  }
  .resume-flip .resume-list .resume-list-back .resume-list-icon {
    font-size: 30px;
    position: relative;
    color: #fff;
    text-align: center;
  }
  .resume-flip .resume-list .resume-list-back h5,
  .resume-flip .resume-list .resume-list-back p {
    color: #fff;
    text-align: center;
    font-size: 18px;
    margin-bottom: 10px;
  }
  .resume-flip .resume-list .resume-list-back button {
    padding: 11px 20px;
    background: #fff;
    border-radius: 10px !important;
    border: 2px solid #fff;
    font-size: 14px;
    border-radius: 2px;
    -webkit-transition: 0.2s;
    transition: 0.2s;
    color: #85bc22 !important;
    font-weight: 900;
    cursor: pointer;
  }
  .resume-flip .resume-list .resume-list-back button:hover {
    background-color: #85bc22;
    border: 2px solid #fff;
    color: #fff !important;
  }
  .resume-flip .resume-list .resume-list-back {
    -webkit-transform: rotateY(-180deg) rotateZ(-180deg);
    transform: rotateY(-180deg) rotateZ(-180deg);
  }
  .resume-flip:hover .resume-list {
    -webkit-transform: rotate3d(1, 0, 0, 180deg);
    transform: rotate3d(1, 0, 0, 180deg);
  }
`;
