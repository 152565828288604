import React from "react";

const SecondLevel = () => {
  return (
    <React.Fragment>
      <p>اطلاعاتی برای نمایش وجود ندارن - مرحله ۲</p>
    </React.Fragment>
  );
};

export default SecondLevel;