import React, { useState } from "react";
import classes from "./Styles/FormStyle.module.css";
import Login from "./Login";
import SignUp from "./SignUp";
import Pay from "./Pay";
import NavBar from "../Landing/NavBar/NavBar";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Form = (props) => {
  console.log(props);
  const [userAction, setUserAtion] = useState(props.starter);
  const [pay, setPay] = useState(false);
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");

  const dangerNotify = (title, closeTime) => {
    toast.error(title, {
      position: "top-right",
      rtl: false,
      autoClose: closeTime,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };

  const successNotify = (title, closeTime) => {
    toast.success(title, {
      position: "top-right",
      rtl: false,
      autoClose: closeTime,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }

  const payHandler = (data) => {
    setName(data.firstname + " " + data.lastname);
    setPhone(data.phone)
    setPay(true);
  };
  const changeActionHandler = (e) => {
    if (e.target.id === "login") setUserAtion("login");
    else if (e.target.id === "signup") setUserAtion("signup");
  };
  return (
    <React.Fragment>
      <ToastContainer />
      <NavBar />
      <div className={classes.container}>
        <div className={classes.outerForm}>
          {!pay && (
            <div style={{ fontSize: "20px", fontWeight: "600", margin: "10px" }}>
              «حِسابِ کاربَریِ مِهرِگان»
            </div>
          )}
          {!pay && (
            <div className={classes.buttonGroup}>
              <button
                className={`${classes.loginGroup} ${
                  userAction === "login" ? "" : classes.loginDeactive
                }`}
                onClick={(e) => changeActionHandler(e)}
                id="login"
              >
                ورود
              </button>
              <button
                className={`${classes.signUpGroup} ${
                  userAction === "signup" ? "" : classes.signUpDeactive
                }`}
                onClick={(e) => changeActionHandler(e)}
                id="signup"
              >
                ثبت نام
              </button>
            </div>
          )}
          {!pay && userAction === "login" && (
            <Login actionLiftUp={changeActionHandler} dangerNotify={dangerNotify} successNotify={successNotify} />
          )}
          {!pay && userAction === "signup" && (
            <SignUp actionLiftUp={changeActionHandler} payLiftUp={payHandler} dangerNotify={dangerNotify}/>
          )}
          {pay && <Pay nameProp={name} phoneProp={phone}/>}
        </div>
      </div>
    </React.Fragment>
  );
};

export default Form;
