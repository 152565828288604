import styles from './Results.module.css'
import gil from '../../assets/Images/gil.png'
import logo from '../../assets/Images/logo.png'
import logoDark from '../../assets/Images/logoDark.png'
import resultFile from '../../assets/File/score.pdf'
import React, { useContext } from 'react';
import { ThemeContext } from '../../Contexts/Theme';
import { useNavigate } from 'react-router-dom'


export default function Results() {
    const [DarkTheme] = useContext(ThemeContext);
    const navigate = useNavigate();

    const DarkThemeStyle = {
        "backgroundColor":"#2E2E2E",
        "color":"white"
    }
    
    const downloadFile = () => {
        const link = document.createElement('a');
        link.href = resultFile; // Replace with the actual file path
        link.download = 'results.pdf'; // Replace with the desired file name
        link.target = '_blank';
        link.click();
    }

    const redirectMainPage = () => {
        window.localStorage.setItem('showCongrats', false)
        navigate('/', { replace: true });
    }
    

    return (
        <>
            <div className={styles['Container']} style={DarkTheme ? DarkThemeStyle : null}>
                <div className={styles['Whole-RightSide']} style={
                !DarkTheme ? 
                    {"backgroundImage":`url(${logo})`,
                    "backgroundRepeat":"no-repeat",
                    "backgroundSize":"contain",
                    "backgroundPosition":"center",
                    }:
                    {
                    "backgroundImage":`url(${logoDark})`,
                    "backgroundRepeat":"no-repeat",
                    "backgroundSize":"contain",
                    "backgroundPosition":"center",
                    }
                }>
                    <div className={styles['CongratsText']} style={DarkTheme ? {"color":"white"} : null}>
                        <div className={styles['CongratsTitle']}>راه یافتگان به مرحله حضوری کارسوق!</div>
                        <span>سلام بچه‌ها! خوش‌حالیم که در مرحله غیرحضوری کارسوق در خدمتتون بودیم!</span>
                        <span>از بین ۲۳۰۰ شرکت کننده‌ای که در کارسوق ریاضی مهرگان امسال شرکت کرده بودند، ۲۰۰ دانش‌آموز موفق به راه‌یابی به مرحله حضوریِ اصفهان کارسوق شدند که به همگی آنها تبریک می‌گیم.</span>
                        <h4>منتظرتون هستیم! :)</h4>
                        <span className={styles['TextDownload']}>برای دانلود لیست قبولی‌ها کلیک کنید!</span>
                    </div>
                    <div className={styles['ButtonGroup']}>
                        <button onClick={downloadFile} className={styles['ResultButton']}>لیست قبولی‌ها</button>
                        <button onClick={redirectMainPage} className={styles['MainSiteButton']}>دیگر نمایش داده نشود</button>
                    </div>
                </div>
                <div className={styles['Whole-LeftSide']}>
                    <img className={styles['GilPicture']} src={gil} />
                </div>
            </div>      
        </>
    )
}