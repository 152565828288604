import styled from "styled-components";
import EstedadFont from "../../../assets/NavBar/Estedad-Regular.woff2";
import EstedadFontBold from "../../../assets/About/Estedad-Bold.woff2";

export const CommentContainer = styled.div`
  @font-face {
    font-display: swap;
    font-family: "Estedad";
    font-style: normal;
    font-weight: normal;
    src: url(${EstedadFont}) format("woff");
  }
  font-family: "Estedad";
  padding: 2rem 10rem;
  @media screen and (max-width: 1000px) {
    padding: 2rem 5rem;
  }
  @media screen and (max-width: 600px) {
    padding: 2rem 1rem;
  }
`;

export const Header = styled.div`
  color: #85bc22;
  font-weight: bold;
  @font-face {
    font-display: swap;
    font-family: "EstedadBold";
    font-style: normal;
    font-weight: bold;
    src: url(${EstedadFontBold}) format("woff");
  }
  font-family: "EstedadBold";
  font-size: 2.5rem;
`;

export const FormWrapper = styled.div`
  margin: 1rem 0;
  @media screen and (max-width: 660px) {
    margin: 1rem 0;
  }
  display: flex;
  flex-wrap: wrap;
`;

export const FieldWrapper = styled.div`
  width: ${(props) => props.w}%;
  @media screen and (max-width: 660px) {
    width: 100%;
  }
  padding: 1rem;
  @media screen and (max-width: 660px) {
   padding:0.5rem;
  }
  display: flex;
  flex-direction: column;
`;

export const Label = styled.label`
  color: black;
  font-size: 16px;
  margin-bottom: 5px;
`;
export const Input = styled.input`
  display: block;
  width: 100%;
  padding: 15px;
  font-size: 1rem;
  padding-left: 60px;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 2px solid #ced4da;
  border-radius: 10px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  outline: none;
  &:focus {
    border-color: #85bc22;
  }
`;
export const TextArea = styled.textarea`
  display: block;
  width: 100%;
  padding: 15px;
  font-size: 1rem;
  resize: vertical;
  height: 10rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 2px solid #ced4da;
  border-radius: 10px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  outline: none;
  &:focus {
    border-color: #85bc22;
  }
`;

export const SubmitBtn = styled.button`
  background-color: #85bc22;
  color: white;
  padding: 0.7rem 1.5rem;
  border-radius: 10px;
  outline: none;
  border: 2px solid transparent;
  cursor: pointer;
  transition: all 0.3s;
  font-weight: 600;
  margin-right: 1rem;
  margin-top: 0.5rem;
  &:disabled{
    opacity:0.5;
    cursor:not-allowd;
  }
  &:hover {
    background-color: white;
    border-color: #85bc22;
    color: #85bc22;
  }
`;
