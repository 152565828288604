import React, { useState } from "react";
import classes from "./Profile.module.css";
import Information from "./Information";
import FirstLevel from "./FirstLevel";
import SecondLevel from "./SecondLevel";
import Messages from "./Messages";
import { FaLock } from "react-icons/fa";
import NavBar from "../Landing/NavBar/NavBar";

const Profile = () => {
  const [userAction, setUserAction] = useState("info");
  const actionHandler = (e) => {
    console.log(e);
    if (e.target.id === "information") setUserAction("info");
    // else if (e.target.id === "first_level") setUserAction("first_level");
    // else if (e.target.id === "second_level") setUserAction("second_level");
    else if (e.target.id === "messages") setUserAction("messages");
  };

  return (
    <React.Fragment>
      <NavBar />
      <div className={classes.container}>
        <div className={classes.outerBox}>
          <div className={classes.buttonGroup}>
            <button
              className={userAction === "info" ? classes.activateBtn : ""}
              onClick={actionHandler}
              id="information"
            >
              اطلاعات
            </button>
            <button
              className={
                userAction === "first_level" ? classes.activateBtn : ""
              }
              onClick={actionHandler}
            >
              <div id="first_level" className={classes.txtWithIcon}>
                <span id="first_level">مرحله یک</span>
                <span id="first_level">
                  <FaLock />
                </span>
              </div>
            </button>
            <button
              className={
                userAction === "second_level" ? classes.activateBtn : ""
              }
              onClick={actionHandler}
              id="second_level"
            >
              <div id="second_level" className={classes.txtWithIcon}>
                <span id="second_level">مرحله دو</span>
                <span id="second_level">
                  <FaLock />
                </span>
              </div>
            </button>
            <button
              className={userAction === "messages" ? classes.activateBtn : ""}
              onClick={actionHandler}
              id="messages"
            >
              پیام‌ها
            </button>
          </div>
          <div className={classes.shownData}>
            {userAction === "info" && <Information />}
            {userAction === "first_level" && <FirstLevel />}
            {userAction === "second_level" && <SecondLevel />}
            {userAction === "messages" && <Messages />}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Profile;
