import React from "react";
import NavBar from "../NavBar/NavBar";
import classes from "./Error404.module.css";
import logo from "./man.gif";

const Error_404 = () => {
  return (
    <React.Fragment>
        <NavBar/>
      <div className={classes.container}>
        <div className={classes.outerDiv}>
          <div className={classes.errorTitle}>
            <span>۴۰۴ - صفحه‌ٔ مورد نظر پیدا نشد</span>
          </div>
          <img src={logo} alt="whereAreWe?" className={classes.img} />
        </div>
      </div>
    </React.Fragment>
  );
};

export default Error_404;
