import React, { useEffect, useState } from "react";
import Navbar from "./NavBar/NavBar";
import Heading from "./Heading/Heading";
import About from "./About/About";
import FAQ from "./FAQ/FAQ.js";
import News from "./News/News";
import Gallery from "./Gallery/Gallery";
import Team from "./Team/Team";
import { useNavigate } from "react-router-dom";
import Comment from "./Comment/Comment.js";

const Landing = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const nav = useNavigate();

  // useEffect(() => {
  //   if (window.localStorage.getItem("showCongrats") !== "false") {
  //     nav("/congrats");
  //   }
  // }, []);

  return (
    <>
      <Navbar isOpen={isOpen} toggle={toggle} />
      <Heading />
      <About />
      <FAQ />
      <News />
      <Gallery />
      <Comment />
      <Team />
    </>
  );
};

export default Landing;
