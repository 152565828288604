import styled from 'styled-components';
import sun from "../../assets/Toggle/sun.svg";
import moon from '../../assets/Toggle/moon.svg';

export const ToggleContainer = styled.div`
    position: absolute;
    top: 85%;
    display: none;
`;

export const Div = styled.div`
    background: ${props => (props.isDark) ? "#4b6cb7" : "#F2994A"};  /* fallback for old browsers */
    background: ${props => (props.isDark) ? "-webkit-linear-gradient(to left, #182848, #4b6cb7)" : "-webkit-linear-gradient(to right, #F2C94C, #F2994A)"};  /* Chrome 10-25, Safari 5.1-6 */
    background: ${props => (props.isDark) ? "linear-gradient(to left, #182848, #4b6cb7)" : "linear-gradient(to right, #F2C94C, #F2994A)"}; /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
    border-radius: 30px 0 0 30px;
    position: fixed;
    width: 4rem;
    height 3rem;
    text-align: center;
    z-index: 5;
    transition: background 1s ease-out, color 250ms ease-in-out;

    :hover {
        cursor: pointer;
    }
`;

export const Sun = styled.div`
    animation: rotation 5s infinite linear;
    display: none;
    background-image:url(${sun});
    background-size: cover;
    background-repeat: no-repeat;
    width: 55%;
    height: 80%;
    margin: 0.2rem 1.2rem 0 0;

    @keyframes rotation {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(360deg);
        }
    }
`;

export const Moon = styled.div`
    display: none;
    background-image:url(${moon});
    background-size: cover;
    background-repeat: no-repeat;
    width: 60%;
    height: 80%;
    margin: 0.2rem 1rem 0 0;
`;