import React, { useState } from "react";

const AuthContext = React.createContext({
  token: "",
  isLoggedIn: "",
  onLogin: (username) => {},
  onLogOut: () => {},
});

export const AuthContextProvider = (props) => {
  const initialToken = localStorage.getItem("token");
  const [token, setToken] = useState(initialToken);
  const userLoggedIn = !!token;
  const logoutHandler = () => {
    setToken("");
    localStorage.removeItem("token");
  };
  const loginHandler = (token) => {
    localStorage.setItem("token", token);
    setToken(token);
  };

  const contextValue = {
    token: token,
    isLoggedIn: userLoggedIn,
    onLogOut: logoutHandler,
    onLogin: loginHandler,
  };

  return (
    <AuthContext.Provider value={contextValue}>
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
