import React, { useRef, useState, useContext, Fragment } from "react";
import axios, * as others from "axios";
import Loading from "../../Utilities/Loading/Loading";
import AuthContext from "../../Contexts/AuthContext";
import classes from "./Styles/FormStyle.module.css";
import { schoolsByCity } from "./cities";

const SignUp = (props) => {
  const phoneNumberRef = useRef();
  const emailRef = useRef();
  const passwordOneRef = useRef();
  const passwordTwoRef = useRef();
  const schoolRef = useRef();
  const [loadedSchools, setLoadedSchools] = useState([]);
  const [data, setData] = useState({});
  const [validatePassword, setValidatePassword] = useState(true);
  const [isLoading, setIsLoading] = useState("false");
  const [errors, setError] = useState([]);
  const AuthCtx = useContext(AuthContext);

  const onChangeInput = (e) => {
    setData((prevData) => {
      return {
        ...prevData,
        [e.target.name]: e.target.value.trim(),
      };
    });
  };
  const handleClickEvent = async (e) => {
    e.preventDefault();
    if (passwordOneRef.current.value != passwordTwoRef.current.value) {
      props.dangerNotify("رمز عبور وارد شده با تکرار آن مطابقت ندارد", 4000);
      return;
    }
    if (!emailRef.current.value.includes("@")) {
      props.dangerNotify("ایمیل وارد شده صحیح نمی‌باشد", 4000);
      return;
    }
    if (
      !phoneNumberRef.current.value.startsWith("09") ||
      phoneNumberRef.current.value.length != 11
    ) {
      props.dangerNotify(
        "شماره تلفن وارد شده صحیح نمی‌باشد. دقت کنید که شماره تلفن خود را بصورت اعداد انگلیسی وارد کرده باشید و صفر اول شماره را نیز فراموش نکرده باشید",
        7000
      );
      return;
    }
    if (data.school == "مدرسه") {
      props.dangerNotify("مدرسه خود را به درستی انتخاب کنید", 5000);
      return;
    }
    if (data.schoolType == "نوع مدرسه") {
      props.dangerNotify("نوع مدرسه خود را به درستی انتخاب کنید", 5000);
      return;
    }
    if (data.grade == "پایه") {
      props.dangerNotify("پایه تحصیلی خود را به درستی انتخاب کنید", 5000);
      return;
    }
    setIsLoading("true");
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/u/auth/users/`,
        {
          ...data,
          grade: parseInt(data.grade),
          ID: data.phone,
        }
      );

      if (response.status === 201) {
        const getTokenAfterSignup = await axios.post(
          `${process.env.REACT_APP_API_URL}/api/u/auth/jwt/create/`,
          {
            email: data.email,
            password: data.password,
          }
        );

        if (getTokenAfterSignup.status === 200) {
          AuthCtx.onLogin(getTokenAfterSignup.data.access);
          props.payLiftUp(response.data);
          setIsLoading("false");
        } else {
          props.dangerNotify(
            "اتفاق غیرمنتظره‌ای رخ داده است، حتما به پشتیبانی سایت برای رفع اشکال پیام دهید",
            6000
          );
          setIsLoading("false");
        }
      } else {
        props.dangerNotify(
          "مشکلی در ارتباط با سرور بوجود آمده، لطفا بعدا مجددا تلاش کنید",
          6000
        );
        setIsLoading("false");
      }
    } catch {
      props.dangerNotify(
        "مشکلی در ارتباط با سرور بوجود آمده، لطفا بعدا مجددا تلاش کنید",
        7000
      );
      setIsLoading("false");
    }
  };
  return (
    <React.Fragment>
      <form className={classes.innerForm}>
        <div className={classes.inputGroup}>
          <input
            onChange={onChangeInput}
            name="firstname"
            type={"text"}
            placeholder="نام"
          ></input>
          <input
            onChange={onChangeInput}
            name="lastname"
            type={"text"}
            placeholder="نام خانوادگی"
          ></input>
        </div>
        <div className={classes.inputGroup}>
          <input
            onChange={onChangeInput}
            ref={phoneNumberRef}
            name="phone"
            type={"text"}
            placeholder="تلفن همراه"
          ></input>
          <input
            onChange={onChangeInput}
            ref={emailRef}
            name="email"
            type={"text"}
            placeholder="ایمیل"
          ></input>
        </div>
        <div className={classes.inputGroup}>
          <input
            onChange={onChangeInput}
            name="province"
            type={"text"}
            placeholder="استان"
          ></input>
          <input
            onChange={onChangeInput}
            name="city"
            type={"text"}
            placeholder="شهر"
          ></input>
        </div>
        <div className={classes.inputGroup}>
          <select
            ref={schoolRef}
            name="school"
            placeholder="مدرسه"
            onChange={onChangeInput}
          >
            <option selected>مدرسه</option>
            {schoolsByCity[data.city] != null &&
              schoolsByCity[data.city].map((item) => {
                return <option>{item}</option>;
              })}
          </select>
          <select
            name="schoolType"
            placeholder="نوع مدرسه"
            onChange={onChangeInput}
          >
            <option selected>نوع مدرسه</option>
            <option>دولتی</option>
            <option>غیرانتفاعی</option>
            <option>استعداد‌های درخشان</option>
          </select>
          <select
            name="grade"
            placeholder="پایه تحصیلی"
            onChange={onChangeInput}
          >
            <option selected>پایه</option>
            <option value={7}>هفتم</option>
            <option value={8}>هشتم</option>
            <option value={9}>نهم</option>
          </select>
        </div>
        <div className={classes.inputGroup}>
          <input
            onChange={onChangeInput}
            ref={passwordOneRef}
            name="password"
            type={"password"}
            placeholder="رمز عبور"
          ></input>
          <input
            onChange={onChangeInput}
            ref={passwordTwoRef}
            name="password2"
            type={"password"}
            placeholder="تکرار رمز عبور"
          ></input>
        </div>
        <button
          disabled={isLoading == "true" ? true : false}
          onClick={handleClickEvent}
          className={`${classes.registerButton} ${
            isLoading == "true" ? classes.disabledRegister : ""
          }`}
          type="submit"
        >
          {isLoading == "false" ? "ثبت نام" : <Loading />}
        </button>
        <p id="login" onClick={props.actionLiftUp}>
          حساب کاربری دارید؟
        </p>
      </form>
    </React.Fragment>
  );
};

export default SignUp;
