import styled from "styled-components";
import EstedadFont from "../../../assets/NavBar/Estedad-Regular.woff2";
import EstedadFontBold from "../../../assets/About/Estedad-Bold.woff2";

export const NewsContainer = styled.div`
  margin: 0;
  object-fit: cover;
  min-height: 92vh;
  display: flex;
  flex-direction: column;
  align-items: start;
  direction: rtl;

  @font-face {
    font-display: swap;
    font-family: "Estedad";
    font-style: normal;
    font-weight: normal;
    src: url(${EstedadFont}) format("woff");
  }
  font-family: "Estedad";

  //can not select link
  -webkit-user-select: none; /* Chrome all / Safari all */
  -moz-user-select: none; /* Firefox all */
  -ms-user-select: none; /* IE 10+ */
  user-select: none; /* Likely future */
`;

export const Header = styled.div`
  color: #85bc22;
  font-weight: bold;
  margin: 2rem 15rem 2rem 0;

  @font-face {
    font-display: swap;
    font-family: "EstedadBold";
    font-style: normal;
    font-weight: bold;
    src: url(${EstedadFontBold}) format("woff");
  }

  font-family: "EstedadBold";
  font-size: 3rem;

  @media screen and (max-width: 1720px) {
    margin: 3rem 12rem 0 0;
  }

  @media screen and (max-width: 1600px) {
    margin: 2rem 10rem 0 0;
  }

  @media screen and (max-width: 1300px) {
    margin: 2rem 4rem 0 0;
  }

  @media screen and (max-width: 800px) {
    margin: 2rem 3rem 0 0;
  }

  @media screen and (max-width: 660px) {
    margin: 2rem 2rem 0 0;
  }

  @media screen and (max-width: 575px) {
    font-size: 2.5rem;
  }

  @media screen and (max-width: 400px) {
    margin-right: 1.5rem;
  }

  @media screen and (max-width: 350px) {
    margin-right: 1rem;
  }
`;

export const Description = styled.div`
  width: 40%;
  background: ${props => (props.isDark) ? "#bdbdbd" : "#f5f5f5"};
  height: 30rem;
  border-radius: 20px;
  margin: 5rem auto;
  padding: 50px 40px;
  transition: all 0.5s ease;

  @media screen and (max-width: 1605px) {
    width: 45%;
  }

  @media screen and (max-width: 1450px) {
    width: 50%;
  }

  @media screen and (max-width: 1290px) {
    width: 55%;
  }

  @media screen and (max-width: 1180px) {
    width: 60%;
  }

  @media screen and (max-width: 1080px) {
    width: 65%;
  }

  @media screen and (max-width: 990px) {
    width: 70%;
  }

  @media screen and (max-width: 900px) {
    width: 75%;
  }

  @media screen and (max-width: 700px) {
    width: 85%;
  }

  @media screen and (max-width: 510px) {
    width: 90%;
    padding: 35px 25px;
  }
`;
  
export const ListContent = styled.div`
  direction: ltr;
  display: ${props => (props.show) ? "flex" : "none"};
  flex-direction: column;
  height: 100%;
  overflow: hidden auto;

  ::-webkit-scrollbar {
    width: 8px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #fff;
    border-radius: 20px;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #999;
    border-radius: 20px;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #666;
  }
`;

export const Question = styled.div`
  direction: rtl;
  border-bottom: 1px solid ${props => (props.isDark) ? "#333" : "#ccc"};
  display: flex;
  flex-direction: row;
  align-items; center;
  color: ${props => (props.isDark) ? "#111" : "#333"};
  font-size: 1.3rem;
  margin: 0.9rem 0;

  div {
    margin-bottom: 0.9rem;

    :hover {
        cursor: pointer;
    }
  }

  @media screen and (max-width: 600px) {
    font-size: 1.1rem;
  }

  @media screen and (max-width: 400px) {
    font-size: 1rem;
  }
`;

export const Dot =styled.span`
  height: 8px;
  width: 8px;
  border-radius: 100%;
  display: inline-block;
  opacity: ${props => (props.show) ? "1" : "0"};
  background-color: #7b27ff;
  margin: 1rem 2rem 1rem 1rem;
  animation: blink 3s infinite ease-in-out;

  @keyframes blink {
    0% {
           background-color: #7b27ff;
           transform: scale(1)
    }
    25% {
           background-color: #18A84E;
           transform: scale(1.5)

    }
    50% {
            background-color: #7b27ff;
           transform: scale(1)

    }
    75% {
            background-color: #18A84E;
           transform: scale(1.5)

    }
    100% {
           background-color: #7b27ff;
           transform: scale(1)

    }

}

`;


export const QuestionText = styled.div`
  font-weight: ${props => (props.lastUnseeItem) ? "800" : ""};
  text-decoration: ${props => (props.lastUnseeItem) ? "underline" : ""};
`;

export const QuestionContent = styled.div`
  direction: ltr;
  display: ${props => (props.show) ? "flex" : "none"};
  flex-direction: column;
  height: 100%;
  overflow: hidden auto;

  ::-webkit-scrollbar {
    width: 8px;
    margin-left: 2rem;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #fff;
    border-radius: 20px;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #999;
    border-radius: 20px;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #666;
  }
`;

export const Heading = styled.div`
  direction: rtl;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 2rem 0 1rem;

  @media screen and (max-width: 550px) {
    flex-direction: column-reverse;
    align-items: end;
  }
`;

export const Text = styled.div`
  color: ${props => (props.isDark) ? "#111" : "#333"};
  direction: rtl;
  font-size: 1.16rem;
  margin: 0 2rem 0 1rem;
  margin-top: 6.5rem;

  @media screen and (max-width: 1000px) {
    margin-top: 3rem;
  }

  @media screen and (max-width: 690px) {
    font-size: 1.06rem;
  }

  @media screen and (max-width: 400px) {
    font-size: 0.96rem;
    margin: 2.5rem 2rem 0 0;
  }
`;

export const RightContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const LeftContainer = styled.div`
  @media screen and (max-width:550px) {
    margin-bottom: 0.5rem;
    margin-right: 1rem;
    transform: translateX(-1rem);
  }
`;

export const Circle = styled.div`
  background: #e6e6e6;
  border-radius: 100%;
  width: 4.5rem;
  height: 4.5rem;

  @media screen and (max-width: 1450px) {
    width: 4rem;
    height: 4rem;
  }

  @media screen and (max-width: 690px) {
    width: 3.5rem;
    height: 3.5rem;
  }

  @media screen and (max-width: 550px) {
    width: 3rem;
    height: 3rem;
  }

  @media screen and (max-width: 400px) {
    width: 2.5rem;
    height: 2.5rem;
  }
`;

export const Arrow = styled.img`
  width: 60%;
  height: 60%;
  transform: rotate(180deg);
  position: relative;
  top: 20%;
  right: 25%;
  transition: all 0.5s ease;

  :hover {
    transform: rotate(180deg) scale(1.2);
    cursor: pointer;
  }
`;

export const QPart = styled.div`
  color: ${props => (props.isDark) ? "#111" : "#333"};
  font-size: 1.3rem;
  margin-left: 0.4rem;

  @media screen and (max-width: 690px) {
    font-size: 1.16rem;
  }

  @media screen and (max-width: 400px) {
    font-size: 1.06rem;
    margin: 0;
  }
`;

export const Date = styled.div`
  color: ${props => (props.isDark) ? "#4d4d4d" : "#333"};
  font-size: 1.3rem;

  @media screen and (max-width: 690px) {
    font-size: 1.16rem;
  }

  @media screen and (max-width: 400px) {
    font-size: 1.06rem;
  }
`;
