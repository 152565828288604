import React from "react";

const Messages = () => {
  return (
    <React.Fragment>
      <p>اطلاعاتی برای نمایش وجود ندارد</p>
    </React.Fragment>
  );
};

export default Messages;
