import styled from 'styled-components';
import EstedadFont from "../../../assets/NavBar/Estedad-Regular.woff2";
import EstedadFontBold from "../../../assets/About/Estedad-Bold.woff2";

export const AboutContainer = styled.div`
    @font-face {
        font-display: swap;
        font-family: 'Estedad';
        font-style: normal;
        font-weight: normal;
        src: url(${EstedadFont}) format('woff');
    }

    font-family: "Estedad";
    display: flex;
    flex-direction: column;
    width: 98vw;
    min-height: 92vh;
    direction: ltr;

    //can not select link
    -webkit-user-select: none;  /* Chrome all / Safari all */
    -moz-user-select: none;     /* Firefox all */
    -ms-user-select: none;      /* IE 10+ */
    user-select: none;          /* Likely future */  

    @media screen and (max-width: 1200px) {
        width: 98vw;
    }

    @media screen and (max-width: 810px) {
        width: 96vw;
    }
`

export const Description = styled.div`
    display: flex;
    flex-direction: column;
    height: 75%;
`

export const Header = styled.div`
    direction: rtl;
    color: ${props => (props.isDark) ? "#a166ff": "#7b27ff"};
    font-weight: bold;
    margin: 3rem 15rem 0 0;

    @font-face {
        font-display: swap;
        font-family: 'EstedadBold';
        font-style: normal;
        font-weight: bold;
        src: url(${EstedadFontBold}) format('woff');
    }

    font-family: "EstedadBold";
    font-size: 3rem;

    @media screen and (max-width: 1720px) {
        margin: 3rem 12rem 0 0;
    }

    @media screen and (max-width: 1600px) {
        margin: 2rem 10rem 0 0;
    }

    @media screen and (max-width: 1300px) {
        margin: 2rem 4rem 0 0;
    }

    @media screen and (max-width: 800px) {
        margin: 2rem 3rem 0 0;
    }

    @media screen and (max-width: 660px) {
        margin: 2rem 2rem 0 0;
    }

    @media screen and (max-width: 575px) {
        font-size: 2.5rem;
    }

    @media screen and (max-width: 470px) {
        font-size: 2rem;
    }

    @media screen and (max-width: 400px) {
        margin-right: 1.5rem;
    }

    @media screen and (max-width: 350px) {
        margin-right: 1rem;
    }
`

export const Content = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin-top: 3rem;
    margin-right: 6rem;
    transition: all 0.5s ease;

    @media screen and (min-width: 1600px) {
        margin-right: 4rem;
    }

    @media screen and (max-width: 1600px) {
        margin-top: 2rem;
    }

    @media screen and (max-width: 1300px) {
        margin-right: 2rem;
    }

    @media screen and (max-width: 1100px) {
        flex-direction: column-reverse;
        align-items: center;
        justify-content: center;
        width: 100%;
    }

    @media screen and (max-width: 660px) {
        margin-right: 0rem;
    }
`

export const Text = styled.div`
    width: 30%;
    direction: rtl;
    color: ${props => (props.isDark) ? "#bdbdbd":"#333"};
    font-size: 1.1rem;

    @media screen and (min-width: 1600px) {
        font-size: 1.2rem;
    }

    @media screen and (max-width: 1720px) {
        width: 35%;
    }

    @media screen and (max-width: 1420px) {
        width: 40%;    
    }

    @media screen and (max-width: 1100px) {
        width: 85%;
    }
`

export const Video = styled.iframe`
    width: 45%;
    border-radius: 10px;
    border: none;

    @media screen and (min-width: 1600px) {
        width: 40%;
        height: 25rem;
    }

    @media screen and (max-width: 1100px) {
        margin-top: 5rem;
        height: 20rem;
        width: 60%;
    }

    @media screen and (max-width: 850px) {
        margin: auto;
        margin-top: 4rem;
        height: 20rem;
        width: 70%;
    }

    @media screen and (max-width: 700px) {
        margin-left: 5rem;
    }

    @media screen and (max-width: 600px) {
        width: 80%;
        height: 15rem;
        margin-left: 3rem;
    }

    @media screen and (max-width: 500px) {
        margin-left: 2.5rem;
    }

    @media screen and (max-width: 450px) {
        width: 90%;
        margin-left: 0.9rem;
    }

    @media screen and (max-width: 350px) {
        margin-left: 0.5rem;
    }
`

export const TimeLine = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    direction: rtl;
    margin: auto;
    margin-top: 5rem;
    width: 50%;
    transition: all 0.5s ease;

    @font-face {
        font-display: swap;
        font-family: 'EstedadBold';
        font-style: normal;
        font-weight: bold;
        src: url(${EstedadFontBold}) format('woff');
    }

    font-family: "EstedadBold";
    font-weight: bold;
    font-size: 1.3rem;


    @media screen and (max-width: 1350px) {
        margin-top: 3rem;
    }

    @media screen and (max-width: 1200px) {
        margin-bottom: 1rem;
    }

    @media screen and (max-width: 950px) {
        font-size: 1.1rem;
    }

    @media screen and (max-width: 820px) {
        flex-direction: column;
    }
`

export const Part = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
    @media screen and (max-width: 1400px) {
        margin-right: ${props => (props.id === "rectangle") ? "1rem" : ""};
    }

    @media screen and (max-width: 950px) {
        margin-right: ${props => (props.id === "rectangle") ? "0rem" : ""};

        margin-right: ${props => (props.id === "hexagon") ? "-1.5rem" : ""};
        margin-left: ${props => (props.id === "hexagon") ? "-1.5rem" : ""};
        
        margin-left: ${props => (props.id === "diamond") ? "-1rem" : ""};
        margin-right: ${props => (props.id === "diamond") ? "-1rem" : ""};
    
        margin-left: ${props => (props.id === "circle") ? "-1rem" : ""};
    }

    @media screen and (max-width: 820px) {
        margin-left: ${props => (props.id === "circle") ? "-10rem" : ""};
        margin-bottom: ${props => (props.id === "circle") ? "-3.5rem" : ""};

        margin-right: ${props => (props.id === "diamond") ? "0" : ""};
        margin-left: ${props => (props.id === "diamond") ? "9rem" : ""};
        margin-top: ${props => (props.id === "diamond") ? "-4rem" : ""};
        margin-bottom: ${props => (props.id === "diamond") ? "-3.5rem" : ""};

        margin-right: ${props => (props.id === "hexagon") ? "0" : ""};
        margin-left: ${props => (props.id === "hexagon") ? "-10rem" : ""};
        margin-top: ${props => (props.id === "hexagon") ? "-4rem" : ""};
        margin-bottom: ${props => (props.id === "hexagon") ? "-4.2rem" : ""};

        margin-right: ${props => (props.id === "rectangle") ? "0" : ""};
        margin-left: ${props => (props.id === "rectangle") ? "10rem" : ""};
        margin-top: ${props => (props.id === "rectangle") ? "-2rem" : ""};
    }
`

export const Dots = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: ${props => (props.id === '1' || props.id === '2') ? "1rem" : "2rem"};
    justify-content: center;
    margin-right: 0rem;
    
    .dot {
        height: 10px;
        width: 10px;
        border-radius: 100%;
        display: inline-block;
        margin-right: 1rem;
    }

    .dot:nth-child(7) {
        background-color: #7b27ff;
    }

    .dot:nth-child(6) {
        background-color: #7b27ffd9;
    }

    .dot:nth-child(5) {
        background-color: #7b27ffd9;
    }

    .dot:nth-child(4) {
        background-color: #7b27ff8c;
    }

    .dot:nth-child(3) {
        background-color: #7b27ff66;
    }
    
    .dot:nth-child(2) {
        background-color: #7b27ff40;
    }

    .dot:nth-child(1) {
        background-color: #7b27ff1a;
    }


    @media screen and (max-width: 1400px) {
        margin: 0;
        margin-right: -1rem;
    }

    @media screen and (max-width: 1200px) {
        .dot:nth-child(even) {
            display: none;
        }
    }

    @media screen and (max-width: 820px) {
        flex-direction: column;
        transform: rotate(${props => (props.id === "1") ? "-45deg" : ((props.id === "3") ? "-50deg" : "45deg")});
        margin-right: ${props => (props.id === "2") ? "-0.5rem" : ""};
        margin-left: ${props => (props.id === "3") ? "-0.7rem" : ""};

        .dot{
            margin-bottom: 0.5rem;
        }
    }
`

export const Title = styled.div`
    color: #85bc22;
    font-weight: bold;
    width: 10rem;
    text-align: center;
`

export const Diamond = styled.div`
    background: ${props => (props.isDark) ? "#d4d4d4" : "#f5f5f5"};
    transform: rotate(45deg);
    width: 7rem;
    height: 7rem;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;

    span {
        transform: rotate(-45deg);
        display: block;
        text-align: center;
    }

    margin-bottom: 1rem;
    margin-top: 3rem;

    @media screen and (max-width: 950px) {
        width: 5rem;
        height: 5rem;
        
        div {
            margin-top: 1.5rem;
        }
    }

    @media screen and (max-width: 750px) {
        width: 5rem;
        height: 5rem;
    }
`

export const Hexagon = styled.div`
    background: ${props => (props.isDark) ? "#d4d4d4" : "#f5f5f5"};
    position: relative;
    width: 10rem;
    aspect-ratio: 1;
    clip-path: polygon(100.00% 50.00%,75.00% 93.30%,25.00% 93.30%,0.00% 50.00%,25.00% 6.70%,75.00% 6.70%);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 2rem;
    text-align: center;

    @media screen and (max-width: 950px) {
        width: 6rem;
        height: 6rem;
        padding: 10% 15%;
    }

    span {
        width: 100%;
}
`

export const Circle = styled.div`
    background: ${props => (props.isDark) ? "#d4d4d4" : "#f5f5f5"};
    border-radius: 100%;
    width: 7rem;
    height: 7rem;
    text-align: center;
    padding: 15px;
    margin-bottom: 2rem;
    display:flex;
    align-items:center;
    justify-content:center;

    @media screen and (max-width: 950px) {
        width: 6rem;
        height: 6rem;
        paddibg: 5px;
    }
`

export const RectangleRounded = styled.div`
    background: ${props => (props.isDark) ? "#d4d4d4" : "#f5f5f5"};
    border-radius: 20px;
    width: 10rem;
    padding: 20px;
    text-align: center;
    margin-top: 2rem;

    @media screen and (max-width: 950px) {
        padding: 15px;
        width: 8rem;
    }
`
