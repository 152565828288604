import React, { useRef, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios, * as others from "axios";
import Loading from "../../Utilities/Loading/Loading";
import classes from "./Styles/FormStyle.module.css";
import AuthContext from "../../Contexts/AuthContext";


const Login = (props) => {
  const emailRef = useRef()
  const passwordRef = useRef()
  const AuthCtx = useContext(AuthContext)
  const [isLoading, setIsLoading] = useState(false)
  const Navigate = useNavigate()

  const loginhandler = async (e) => {
    e.preventDefault();
    setIsLoading(true)
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/u/auth/jwt/create/`,
      {
        email: emailRef.current.value,
        password: passwordRef.current.value,
      }
    );
    setIsLoading(false)
    if (response.status === 200) {
      props.successNotify("ورود شما با موفقیت انجام شد، به صفحه کاربری منتقل می‌شوید", 4000)
      AuthCtx.onLogin(response.data.access);
      Navigate("/profile")
    }
    else {
      props.dangerNotify("مشکلی پیش آمده", 4000)
    }

  }
  return (
    <React.Fragment>
      <form onSubmit={loginhandler} className={classes.innerForm}>
        <input ref={emailRef} type={"text"} placeholder="ایمیل"></input>
        <input ref={passwordRef} type={"password"} placeholder="رمزعبور"></input>
        <button type="submit">{isLoading === false ? "ورود" : <Loading/>}</button>
        <p id="signup" onClick={props.actionLiftUp}>حساب کاربری ندارید؟</p>
      </form>
    </React.Fragment>
  );
};

export default Login;
