import React, { useContext, useState } from "react";
import AuthContext from "../../Contexts/AuthContext";
import axios, * as others from "axios";
import classes from "./Styles/FormStyle.module.css";
import Loading from "../../Utilities/Loading/Loading";

const Login = (props) => {
  const { token } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);
  console.log(token);
  const goGateway = async () => {
    setIsLoading(true);
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/purchase/`,
      {
        price: 30000,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${token}`,
        },
      }
    );
    if (response.status === 200) {
      setIsLoading(false);
      window.location.href = response.data.payment_link;
    }
  };
  return (
    <React.Fragment>
      <div className={classes.paymentForm}>
        <h3 className={classes.titlePayment}>«تایید اطلاعات و نهایی شدن ثبت‌نام»</h3>
        <div className={classes.registerationInfoContainer}>
          <span>نام و نام خانوادگی شرکت کننده:</span>
          <span className={classes.TextPay}><b>{props.nameProp}</b></span>
        </div>
        <div className={classes.registerationInfoContainer}>
          <span>تلفن همـــــراه شرکت کننده:</span>
          <span className={classes.TextPay}><b>{props.phoneProp}</b></span>
        </div>
        <div className={classes.registerationInfoContainer}>
          <span>هزینـــــــهٔ شرکت در رویداد:</span>
          <span className={classes.TextPay}><b>۳۰ هزارتومان</b></span>
        </div>
        <button onClick={goGateway} type="submit">
          {isLoading == false ? "پرداخت" : <Loading />}
        </button>
      </div>
    </React.Fragment>
  );
};

export default Login;
