import React, {useContext} from "react";
import { FaBars } from "react-icons/fa";
import Sidebar from "./Sidebar/Sidebar";
import { ThemeContext } from "../../../Contexts/Theme";
import AuthContext from "../../../Contexts/AuthContext";

import {
  MobileIcon,
  Nav,
  NavbarContainer,
  NavBtn,
  NavBtnLink,
  NavItem,
  NavLinks,
  NavMenu,
  KarsoghLogo,
} from "./NavBarStyle.js";

const NavBar = (props) => {
  const AuthCtx = useContext(AuthContext)
  const [DarkTheme] = useContext(ThemeContext);
  const shit = document.body.style.background = DarkTheme ? "#292929": "#fff";

  return (
    <>
      <Sidebar isOpen={props.isOpen} toggle={props.toggle} />
      <Nav isDark={DarkTheme} >
        <NavbarContainer>
          <MobileIcon onClick={props.toggle} isDark={DarkTheme} >
            <FaBars />
          </MobileIcon>
          <NavBtn>
            {/* <NavBtnLink to="/congrats" bg={"#85bc22"} filled={"true"}>
               پذیرفته شدگان مرحله حضوری
            </NavBtnLink> */}
            {/* <NavBtnLink to="/login" bg={(DarkTheme) ? "#fff" : "#333333"}>
              ورود
            </NavBtnLink> */}
          </NavBtn>
          <NavMenu>
            <NavItem>
              <NavLinks
                to="Team"
                smooth={true}
                duration={500}
                spy={true}
                exact="true"
                offset={-80}
                isDark={DarkTheme}
              >
                کادر برگزاری
              </NavLinks>
            </NavItem>

            <NavItem>
              <NavLinks
                to="Comment"
                smooth={true}
                duration={500}
                spy={true}
                exact="true"
                offset={-80}
                isDark={DarkTheme}
              >
                 انتقاد و پیشنهاد
              </NavLinks>
            </NavItem>

            <NavItem>
              <NavLinks
                to="Gallery"
                smooth={true}
                duration={500}
                spy={true}
                exact="true"
                offset={-80}
                isDark={DarkTheme}
              >
                 گالری تصاویر
              </NavLinks>
            </NavItem>

            <NavItem>
              <NavLinks
                to="News"
                smooth={true}
                duration={500}
                spy={true}
                exact="true"
                offset={-80}
                isDark={DarkTheme}
              >
                اخبار
              </NavLinks>
            </NavItem>

            <NavItem>
              <NavLinks
                to="FAQ"
                smooth={true}
                duration={500}
                spy={true}
                exact="true"
                offset={-80}
                isDark={DarkTheme}
              >
                پرسش و پاسخ
              </NavLinks>
            </NavItem>

            <NavItem>
              <NavLinks
                to="About"
                smooth={true}
                duration={500}
                spy={true}
                exact="true"
                offset={-80}
                isDark={DarkTheme}
              >
                درباره
              </NavLinks>
            </NavItem>

            <NavItem>
              <NavLinks
                to="Home"
                smooth={true}
                duration={500}
                spy={true}
                exact="true"
                offset={-80}
                isDark={DarkTheme}
              >
                خانه
              </NavLinks>
            </NavItem>

            <NavItem hover={"none"}>
              <NavLinks
                to="Home"
                smooth={true}
                duration={500}
                spy={true}
                exact="true"
                offset={-80}
                Logo={true}
              >
                <KarsoghLogo isDark={DarkTheme} />
              </NavLinks>
            </NavItem>
          </NavMenu>
        </NavbarContainer>
      </Nav>
    </>
  );
};

export default NavBar;
