import React, { useContext } from 'react'
import { Link as LinkS } from 'react-scroll'
import { ThemeContext } from '../../../../Contexts/Theme.js';
import {
    CloseIcon,
    Icon,
    SidebarContainer,
    SidebarLink,
    SidebarMenu,
    SidebarRoute,
    SidebarWrapper,
    SideBtnWrap,
    KarsoghLogo
} from './SidebarStyle.js'

const Sidebar = ({ isOpen, toggle }) => {
    const [DarkTheme] = useContext(ThemeContext);

    return (
        <>
            <LinkS to="Home" smooth={true} duration={500} spy={true} exact='true' offset={-80} >
                <KarsoghLogo isDark={DarkTheme} />
            </LinkS>
            <SidebarContainer isOpen={isOpen} isDark={DarkTheme} >
                <Icon onClick={toggle}>
                    <CloseIcon isDark={DarkTheme} />
                </Icon>
                <SidebarWrapper>
                    <SidebarLink
                        to="Home"
                        onClick={toggle}
                        smooth={true}
                        duration={500}
                        spy={true}
                        exact='true'
                        offset={-80}
                        isDark={DarkTheme}
                    >خانه</SidebarLink>
                    <SidebarLink
                        to="About"
                        onClick={toggle}
                        smooth={true}
                        duration={500}
                        spy={true}
                        exact='true'
                        offset={-80}
                        isDark={DarkTheme}
                    >درباره</SidebarLink>
                    <SidebarLink
                        to="FAQ"
                        onClick={toggle}
                        smooth={true}
                        duration={500}
                        spy={true}
                        exact='true'
                        offset={-80}
                        isDark={DarkTheme}
                    >پرسش و پاسخ</SidebarLink>
                    <SidebarLink
                        to="News"
                        onClick={toggle}
                        smooth={true}
                        duration={500}
                        spy={true}
                        exact='true'
                        offset={-80}
                        isDark={DarkTheme}
                    >اخبار</SidebarLink>
                        <SidebarLink
                        to="Gallery"
                        onClick={toggle}
                        smooth={true}
                        duration={500}
                        spy={true}
                        exact='true'
                        offset={-80}
                        isDark={DarkTheme}
                    >گالری تصاویر</SidebarLink>
                            <SidebarLink
                        to="Comment"
                        onClick={toggle}
                        smooth={true}
                        duration={500}
                        spy={true}
                        exact='true'
                        offset={-80}
                        isDark={DarkTheme}
                    >انتقاد وپیشنهاد</SidebarLink>
                    <SidebarLink
                        to="Team"
                        onClick={toggle}
                        smooth={true}
                        duration={500}
                        spy={true}
                        exact='true'
                        offset={-80}
                        isDark={DarkTheme}
                    >کادر برگزاری</SidebarLink>

                    {/* <SideBtnWrap isOpen={isOpen}> */}
                        {/* <SidebarMenu> */}
                            {/* <SidebarRoute to="/track" bg={"#333"}>ورود</SidebarRoute> */}
                            {/* <SidebarRoute to="/congrats" bg={"#85bc22"}>پذیرفته شدگان مرحله حضوری</SidebarRoute> */}
                        {/* </SidebarMenu> */}
                    {/* </SideBtnWrap> */}

                </SidebarWrapper>
            </SidebarContainer>
        </>
    )
}

export default Sidebar
