import React, { useContext, useEffect, useState } from 'react';
import Logo from "../../../assets/Heading/Big_Logo.svg";
import Logo_Dark from "../../../assets/Heading/Big_Logo_Dark.svg";
import { ThemeContext } from '../../../Contexts/Theme';
import {
    FAQContainer,
    Header,
    Description,
    LeftContainer,
    RightContainer,
    Part,
    KarsoghImage
} from "./FAQStyle.js"


const FAQ = (props) => {
    const [DarkTheme] = useContext(ThemeContext);

    useEffect(() => {
        let question = document.querySelectorAll(".question");

        question.forEach((qsitem) => {
            qsitem.addEventListener("click", function (e) {
                //   store the .answer div containing the answer
                let sibling = qsitem.nextElementSibling;

                // Nested loop for removing active class from all and set answer height to 0
                question.forEach((item) => {
                item.nextElementSibling.style.height = "0px";
                //   remove class "active" except for the currently clicked item
                item != qsitem && item.parentNode.classList.remove("active");
                });
                //then toggle the "active" class of clicked item's parent ".qna"
                this.parentNode.classList.toggle("active");

                // set actual height for .answer div if .qna has the class "active"
                if (qsitem.parentNode.classList.contains("active")) {
                sibling.style.height = sibling.scrollHeight + "px";
                } else {
                sibling.style.height = "0px";
                }
            });
        });
    }, [])
    
    return (
        <FAQContainer id="FAQ" >
            <Header isDark={DarkTheme} >
                سوالات متداول
            </Header>
            <Description>
                <RightContainer isDark={DarkTheme} >
                    <Part isDark={DarkTheme} >
                        <div className="qna">
                            <h3 className="question">کارسوق چیه؟</h3>
                            <div className="answer">
                                <p>
                                    کارسوق یا همون Workshop جاییه که دور هم جمع می‌شیم تا با هم چیزای جدید یاد بگیریم و کارای باحال جدید بکنیم ولی حالا توی کارسوق ریاضی مهرگان قراره که در کنار هم، توی دنیای ریاضیات سفر کنیم و جور دیگه ای بهش نگاه کنیم. اگه دوست دارید لذت ریاضی واقعی رو تجربه کنید ، توی این ماجراجویی با ما همراه باشید
                                </p>
                            </div>
                        </div>
                    </Part>
                    <Part isDark={DarkTheme} >
                        <div className="qna">
                            <h3 className="question">دانش‌آموزای چه پایه‌هایی می‌تونن ثبت‌نام کنن؟</h3>
                            <div className="answer">
                                <p>
                                    همهٔ دانش‌آموزان سمپادی دبیرستان دوره اول متوسطه (پایه‌های هفتم، هشتم ، نهم) می‌تونند توی کارسوق ثبت‌نام کنند.
                                </p>
                            </div>
                        </div>
                    </Part>
                    <Part isDark={DarkTheme} >
                        <div className="qna">
                            <h3 className="question">چجوری باید ثبت‌نام کنم؟</h3>
                            <div className="answer">
                                <p>
                                    از طریق وبسایت mehregan.sampad.gov.ir ثبت نام کنید
                                </p>
                            </div>
                        </div>
                    </Part>
                    <Part isDark={DarkTheme} >
                        <div className="qna">
                            <h3 className="question">جایزه هم داره؟</h3>
                            <div className="answer">
                                <p>
                                    معلومه که بله! راه یافتگان به مرحله تابستانه، هریک یادبودی از طرف تیم کارسوق هدیه می‌گیرند و به دانش‌آموزای برتر هم جوایز ارزنده‌ای تعلق می‌گیره
                                </p>
                            </div>
                        </div>
                    </Part>
                    <Part isDark={DarkTheme} >
                        <div className="qna">
                            <h3 className="question">چند مرحله آزمون باید بدیم؟</h3>
                            <div className="answer">
                                <p>
                                    کارسوق امسال برخلاف سال‌های گذشته بصورت سه مرحله‌ای برگزار می‌شود و راه‌یافتگان به دورهٔ تابستانه، کارسوق ریاضی را بصورت حضوری تجربه می‌کنن
                                </p>
                            </div>
                        </div>
                    </Part>
                    <Part isDark={DarkTheme} >
                        <div className="qna">
                            <h3 className="question">هزینه‌ای هم داره؟</h3>
                            <div className="answer">
                                <p>
                                    برای شرکت تو کارسوق باید ۱۵۰ هزارتومن پرداخت کنید. برای دورهٔ تابستانه هم مقداری بعنوان هزینهٔ جایزه و یادبودها ازتون گرفته می‌شه
                                </p>
                            </div>
                        </div>
                    </Part>
                </RightContainer>
                <LeftContainer>
                    <KarsoghImage src={DarkTheme ? Logo_Dark : Logo} />
                </LeftContainer>
            </Description>
        </FAQContainer>
    );
}
 
export default FAQ;
