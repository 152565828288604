import React, { useState, useCallback, useEffect, useContext } from "react";
import RightArrow from "../../../assets/Heading/right-arrow.svg";
import axios from "axios";
import { ThemeContext } from "../../../Contexts/Theme";
import {
  NewsContainer,
  Header,
  Description,
  ListContent,
  Question,
  Dot,
  QuestionContent,
  QuestionText,
  Heading,
  Text,
  RightContainer,
  LeftContainer,
  Date,
  QPart,
  Circle,
  Arrow,
} from "./NewsStyle.js";

function convertNumbersToPersian(list) {
  var persianNumbers = ["۰", "۱", "۲", "۳", "۴", "۵", "۶", "۷", "۸", "۹"];
  var result = [];

  for (var i = 0; i < list.length; i++) {
    var item = list[i];
    var convertedItem = {};

    for (var key in item) {
      if (item.hasOwnProperty(key)) {
        var value = item[key];
        if (key === "date") {
          value = value.toString().replace(/\d/g, function (num) {
            return persianNumbers[num];
          });
        }
        convertedItem[key] = value;
      }
    }

    result.push(convertedItem);
  }

  return result;
}

const News = (props) => {
  const [showList, setShowList] = useState(true);
  const [news, setNews] = useState([]);
  const [DarkTheme] = useContext(ThemeContext);

  if (localStorage.getItem("news-seen") === null) {
    localStorage.setItem("news-seen", JSON.stringify({}));
  }

  const fetchNews = useCallback(async () => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/contact/news/`
    );
    const cnvData = convertNumbersToPersian(data);
    // sort with their id
    cnvData.sort((a, b) => {
      if (a["id"] > b["id"]) return -1;
      else if (a["id"] < b["id"]) return 1;
      else return 0;
    });
    setNews(cnvData);
  }, []);

  useEffect(() => {
    async function temp() {
      await fetchNews();
    }

    temp();
  }, [fetchNews]);

  const handleShowNews = (event) => {
    setShowList(false);
    let temp = [...news];
    temp.filter((element) => {
      return element.id == event;
    })[0].show = true;
    localStorage.setItem(
      "news-seen",
      JSON.stringify({
        ...JSON.parse(localStorage.getItem("news-seen")),
        [event]: true,
      })
    );
    setNews(temp);
  };

  const handleHideNews = (event) => {
    setShowList(true);
    let temp = [...news];
    temp.filter((element) => {
      return element.id == event;
    })[0].show = false;
    setNews(temp);
  };

  return (
    <NewsContainer id="News">
      <Header>اخبار و اطلاعیه ها</Header>
      <Description isDark={DarkTheme}>
        <ListContent show={showList}>
          {news.map((element) => {
            // find maximum element.id in the list
            const max = Math.max.apply(
              Math,
              news.map(function (o) {
                return o.id;
              })
            );
            const isLastUnseenNew =
              JSON.parse(localStorage.getItem("news-seen"))[element.id] !=
                true && element.id === max;
            return (
              <Question key={element.id} isDark={DarkTheme}>
                <div>
                  <Dot
                    show={
                      JSON.parse(localStorage.getItem("news-seen"))[
                        element.id
                      ] != true
                    }
                  />
                </div>
                <QuestionText
                  lastUnseeItem={isLastUnseenNew}
                  onClick={() => handleShowNews(element.id)}
                  id={element.id}
                >
                  {element.question}
                </QuestionText>
              </Question>
            );
          })}
        </ListContent>

        <QuestionContent show={!showList}>
          {news.map((element) => {
            console.log(element);
            if (element.show) {
              return (
                <>
                  <Heading key={element.id}>
                    <RightContainer>
                      <QPart isDark={DarkTheme}>{element.question}</QPart>
                      <Date isDark={DarkTheme}>{element.date}</Date>
                    </RightContainer>
                    <LeftContainer>
                      <Circle>
                        <Arrow
                          onClick={() => handleHideNews(element.id)}
                          src={RightArrow}
                          id={element.id}
                        />
                      </Circle>
                    </LeftContainer>
                  </Heading>
                  <Text
                    isDark={DarkTheme}
                    key={element.id}
                    dangerouslySetInnerHTML={{__html:element.answer}}
                  ></Text>
                </>
              );
            }
          })}
        </QuestionContent>
      </Description>
    </NewsContainer>
  );
};

export default News;
