import React from "react";
import "./Loading.css"

const Loading = () => {
    return (
        <React.Fragment>
            <div class="loading">...</div>
        </React.Fragment>
    )
}

export default Loading;