import { createRoot } from "react-dom/client";
import { AuthContextProvider } from "./Contexts/AuthContext";
import "./index.css";
import App from "./App";
import { ThemeProvider } from "./Contexts/Theme";

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

root.render(
  <AuthContextProvider>
    <ThemeProvider>
      <App />
    </ThemeProvider>
  </AuthContextProvider>
);
