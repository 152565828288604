import React from "react";

const FirstLevel = () => {
  return (
    <React.Fragment>
      <p>اطلاعاتی برای نمایش وجود ندارد - مرحله ۱</p>
    </React.Fragment>
  );
};
export default FirstLevel;
