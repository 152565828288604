import React, { useContext } from "react";
import { BrowserRouter as Router, Route, Routes, redirect } from "react-router-dom";
import Form from "./views/EnterUser/Form";
import AuthContext from "./Contexts/AuthContext";
import Landing from "./views/Landing/Landing";
import Profile from "./views/Profile/Profile";
import Error_404 from "./views/Landing/Errors/Error404";
import Toggle from "./views/Toggle/Toggle";
import Results from "./Pages/Results/Results";

function App() {
  const AuthCtx = useContext(AuthContext);

  return (
    <>
      <Toggle />
      <Router>
        <Routes>
          <Route exact path="/" element={<Landing />} />
          <Route exact path ="/congrats" element={<Results/>}/>
          <Route exact path="/profile" element={<Profile />} />
          <Route exact  path="/signup" element={AuthCtx.isLoggedIn !== true ?  <Form starter={"signup"} /> : <Profile />} />
          <Route exact  path="/login" element={AuthCtx.isLoggedIn !== true ?  <Form starter={"login"} /> : <Profile />} />
          <Route path="/*" element={<Error_404/>}/>
          {/* ------------ */}
          {/* <Route exact  path="/signup" element={<Form starter={"signup"} />}/>
          <Route exact  path="/login" element={<Form starter={"login"} />}/> */}
          {/* ------------ */}
          {/* <Route exact  path="/signup" element={<Landing />}/>
          <Route exact  path="/login" element={<Landing />}/> */}
        </Routes>
      </Router>
    </>
  );
}

export default App;
