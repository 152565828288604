import React, { useContext, useEffect } from 'react';
import { ThemeContext } from '../../Contexts/Theme';
import $ from 'jquery';
import {
    ToggleContainer,
    Div,
    Sun,
    Moon
} from "./ToggleStyle.js";

const Toggle = (props) => {
    const [DarkTheme, toggleTheme] = useContext(ThemeContext);

    const handleToggle = (e) => {
        if(DarkTheme) {
            $(document).ready(function(){
                $("#moon").css("display", "none");
                $("#sun").fadeIn("slow");
            });
        }
        else {
            $(document).ready(function(){
                $("#sun").css("display", "none");
                $("#moon").fadeIn("slow");
            });
        }

        toggleTheme();
    }

    useEffect(() => {
        if(DarkTheme) {
            $(document).ready(function(){
                $("#sun").css("display", "none");
                $("#moon").fadeIn("slow");
            });
        }
        else {
            $(document).ready(function(){
                $("#moon").css("display", "none");
                $("#sun").fadeIn("slow");
            });
        }
    }, [DarkTheme])

    return (
        <ToggleContainer id='toggle' >
            <Div onClick={handleToggle} isDark={DarkTheme} >
                <Sun id='sun' isDark={DarkTheme} />
                <Moon id='moon' isDark={DarkTheme} />
            </Div>
        </ToggleContainer>
    );
}
 
export default Toggle;
